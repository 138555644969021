var gomaye = gomaye || {};

( function () {
    'use strict';
    jQuery( function () {
        gomaye.init();
    } );

    gomaye.init = function () {
        gomaye.socials.init();

    };

    gomaye.socials = {
        init: function () {
            gomaye.socials.instagram.init();
        },
        instagram: {
            init: function () {
                if ( jQuery( '#instagram-feed' ).length > 0 ) {
                    jQuery.getScript( '/Files/Templates/Designs/gomaye/_assets/js/vendor/instafeed.js', function () {
                        gomaye.socials.instagram.build();
                    } );
                }

            },
            build: function () {
                var jFeed = jQuery( '.instagram-feed' ),
                    jLimit = parseInt( jFeed.data( 'limit' ) ),
                    jCaption = jFeed.data( 'caption' ),
                    jCaptions = '',
                    jUserType = jFeed.data( 'type' ),
                    //jUserTypes = '',
                    jGet = 'user',
                    jUser = jFeed.data( 'user' ),
                    jToken = jFeed.data( 'token' ),
                    jTagged = jFeed.data( 'tags' );
                if ( jCaption === 'True' ) {
                    jCaptions = '<div class="instagram-feed-item-caption"><p>{{caption}}</p></div>';
                }
                if ( jUserType === 'True' ) {
                    jGet = 'user';
                }
                var i = new Instafeed( {
                    get: jGet,
                    tagName: jTagged,
                    userId: jUser,
                    accessToken: jToken,
                    limit: jLimit,
                    success: function ( data ) {
                        //console.log( data );
                        if ( jGet === 'user' ) {
                            jTagged = data.data[ 0 ].user.username;
                        }
                    },
                    after: function () {
                        var items = jQuery( '.instagram-feed-item' )
                        for ( var i = 0; i < items.length; i += 4 ) {
                            var active = '';
                            if ( i === 0 ) {
                                active = 'active';
                            }
                            items.slice( i, i + 4 ).wrapAll( '<div class="p-carousel-item p-carousel-item-gradient-disabled carousel-item ' + active + '"><div class="instagram-feed-inner"></div></div>' );
                        }
                    },
                    target: 'instagram-feed',
                    resolution: 'standard_resolution',
                    template: '<div class="instagram-feed-item" style="background-image:url({{image}})"><img src="/Files/Templates/Designs/gomaye/_assets/img/trans.png" title="{{caption}}">'+jCaptions+'</div>'
                } );
                i.run();
                //<div class="instagram-feed-item"><a style="background-image:url({{image}}); background-size:cover;" href="{{link}}" target="_blank"><img src="/Files/Templates/Designs/nordlux/assets/img/trans.png" title="{{caption}}"/>' + jCaptions + '</a></div>
            }
        }
    };
}() );

